@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --clr-accent: #ff269e;
  --clr-accent-50: #fef1f9;
  --clr-accent-100: #fee5f4;
  --clr-accent-200: #ffcaec;
  --clr-accent-300: #ff9fdb;
  --clr-accent-400: #ff63c0;
  --clr-accent-500: #ff269e;
  --clr-accent-600: #f01282;
  --clr-accent-700: #d10566;
  --clr-accent-800: #ad0754;
  --clr-accent-900: #8f0c49;
  --clr-accent-950: #580027;

  --clr-accent-hover: var(--clr-accent-400);

  --clr-cyan: #00ffff;
  --clr-magenta: #ff00ff;
  --clr-yellow: #ffff00;
  --clr-mint: #00d29a;

  --clr-primary-light: #fff;
  --clr-primary-dark: #1b1a21;
}
@layer base {
  .dark {
    --clr-primary-text: var(--clr-primary-light);
  }
  .light {
    --clr-primary-text: var(--clr-primary-dark);
  }
  body {
    @apply bg-dark font-sans leading-loose text-primary;
  }
  .h1 {
    @apply font-semibold leading-[1.1] tracking-wider ~text-[3rem]/[5rem];
  }
  .h2 {
    @apply font-secondary font-semibold leading-[1.1] ~text-[2.25rem]/[3rem];
  }
  .h3 {
    @apply font-secondary font-semibold leading-[1.1] ~text-[1.25rem]/[1.5rem];
  }
}

@layer components {
  .section-px {
    @apply ~px-5/12;
  }
  .section-py {
    @apply ~py-20/36;
  }
  .section-p {
    @apply section-px section-py;
  }
  .section-w {
    @apply mx-auto w-full max-w-7xl;
  }
  .section-w-lg {
    @apply section-w max-w-screen-2xl;
  }
  main {
    .has-visual {
      @apply md:pt-80 lg:pt-0;
    }
  }

  .underline-impl {
    @apply bg-gradient-to-r from-current to-current bg-[length:100%_0.06875rem] bg-left-bottom bg-no-repeat transition-[color,_background-size] [transition-duration:300ms,500ms];
  }

  .underline-impl-hover {
    @apply bg-[length:0%_0.06875rem] bg-right-bottom;
  }

  .underline-impl-inv {
    @apply bg-[length:0%_0.06875rem] bg-right-bottom;
  }

  .underline-impl-inv-hover {
    @apply bg-[length:100%_0.06875rem] bg-left-bottom;
  }

  .underline-link,
  .underline-links a {
    @apply underline-impl hover:underline-impl-hover;
    &:where(
        .maps-links,
        .phone-number,
        .underline-link-inverted,
        .underline-links-inverted a
      ) {
      @apply underline-impl-inv hover:underline-impl-inv-hover;
    }
  }
  .underline-link-span {
    @apply [&>span]:underline-impl [&>span]:hover:underline-impl-hover;
    &:where(.underline-link-inverted) {
      @apply [&>span]:underline-impl-inv [&>span]:hover:underline-impl-inv-hover;
    }
  }

  .text-outline {
    -webkit-text-stroke: 1px white;
    @apply text-transparent;
  }
  .text-outline-hover {
    -webkit-text-stroke: 1px var(--clr-accent);
  }
  .text-outline-shadow {
    /* --width: 0.03125rem; */
    --width: 0.0625rem;
    text-shadow:
      calc(-1 * var(--width)) calc(-1 * var(--width)) 0 var(--clr-primary-text),
      var(--width) calc(-1 * var(--width)) 0 var(--clr-primary-text),
      calc(-1 * var(--width)) var(--width) 0 var(--clr-primary-text),
      var(--width) var(--width) 0 var(--clr-primary-text);
  }

  .text-outline-shadow-secondary {
    /* --width: 0.03125rem; */
    --width: 0.09375rem;
    text-shadow:
      calc(-1 * var(--width)) calc(-1 * var(--width)) 0 var(--clr-primary-dark),
      var(--width) calc(-1 * var(--width)) 0 var(--clr-primary-dark),
      calc(-1 * var(--width)) var(--width) 0 var(--clr-primary-dark),
      var(--width) var(--width) 0 var(--clr-primary-dark);
  }

  /* .gradient-mask {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100'%3E%3Cdefs%3E%3ClinearGradient id='grad' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='white' stop-opacity='1' /%3E%3Cstop offset='20%25' stop-color='white' stop-opacity='0' /%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='url(%23grad)' /%3E%3C/svg%3E");
        mask-size: cover;
        mask-repeat: no-repeat;
    } */
}

/* hack for safari circular text */
_::-webkit-full-page-media,
_:future,
:root .circular-text-item {
  transform: translate(-50%, -50%)
    rotate(calc(var(--inner-angle) * var(--index)))
    translateY(calc(var(--radius) * 0.85)) !important;
}

_::-webkit-full-page-media,
_:future,
:root .circular-text {
  @apply text-primary/20;
}

/* 
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

canvas {
    opacity: 0;
    animation: fade-in 5s ease 1s forwards;
} */
